import {GridType} from '../../types/galleryTypes';
import {IStylesParamsValues} from '../../baseStylesParams';

export function productsPerPage(styleParams: IStylesParamsValues) {
  const {gallery_productsCount, galleryRows, galleryColumns, gallery_gridType} = styleParams;

  return {
    [GridType.MANUAL]: galleryRows * galleryColumns,
    [GridType.AUTO]: gallery_productsCount,
  }[gallery_gridType];
}
